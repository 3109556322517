
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
//window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
window.moment = require('moment');
require('devbridge-autocomplete');
require('jquery-datetimepicker');
require('lightbox2');
require('bootstrap-fileinput');
require('cookieconsent');
/*
require('spectrum-colorpicker/spectrum.js');
require('bootstrap-select');
require('bootstrap-fileinput');
require('bootstrap4-toggle');


 */
//    window.moment = require('moment');

/**
 window.Vue = require('vue');

 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

/*Vue.component('example-component', require('./components/ExampleComponent.vue'));

const app = new Vue({
    el: '#app'
});

*/